import React from 'react'
import logo from "../../assets/images/logo.png"
import { ButtonWrapper, HomeWrapper, LogoWrapper } from './HomeElements'


const HomePage = () => {
  return (
    <HomeWrapper>
      <LogoWrapper src={logo} />
      <ButtonWrapper
        activeClass="active"
        to="contact"
        spy={true}
        smooth={true}
        duration={500}
      >Prenez rendez-vous maintenant!</ButtonWrapper>
    </HomeWrapper>
  )
}

export default HomePage