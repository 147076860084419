import styled from 'styled-components';

export const ServicesWrapper = styled.div`
  width: 100vw;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  background-color: ${props => props.theme.color.dark};
  padding: 50px;
`

export const ServiceBox = styled.div`
  border: solid 1px ${props => props.theme.color.primary};
  border-radius: 50px;
  min-height: 280px;
  height: auto;
  width: 220px;
  margin: 30px 5vw;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px;
`

export const ServiceBoxWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
`

export const ServiceText = styled.div`
  font-family: ${props => props.theme.font.secondary};
  font-size: ${({ ml }) => (ml ? '1.5rem' : '1.2em')}; 
  line-height: 1.4;
  color: ${props => props.theme.color.primary};
  text-align: center;
`

export const ServiceImg = styled.img`
  width: 60%;
  height: auto;
  margin: 10px 0;
`