import React from 'react'
import { Parallax } from 'react-parallax';
import { ParallaxText, ParallaxWrapper } from './ParallaxElements';

const ParallaxPage = () => {
  return (

    <Parallax
      bgClassName='object-bottom'
      bgImage="/assets/images/parallax.jpeg"
      bgImageAlt="Changement de pneus a domicile"
      strength={200}>
      <ParallaxWrapper>
        <ParallaxText>PROFESSIONNEL</ParallaxText>
        <ParallaxText>SÉCURITAIRE</ParallaxText>
        <ParallaxText>RAPIDE</ParallaxText>
      </ParallaxWrapper>
    </Parallax>


  )
}

export default ParallaxPage