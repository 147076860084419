import React from "react";
import { ThemeProvider } from "styled-components";

const theme = {
  color: {
    primary: "#F5B301",
    secondary: "#FED053",
    third: "#3B3F46",
    dark: "#1E2328", 
    bright: "#FFFFFF"
  },
  font: {
    primary: "'Josefin Sans', serif",
    secondary: "'Righteous', serif"
  },
  fontSize: {
    xxs: "0.7rem",
    xs: "0.85rem",
    sm: "1em",
    md: "1.2em",
    ml: "1.5em",
    lg: "2rem",
    xl: "3rem",
    xxl: "4rem",
  }
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;