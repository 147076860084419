import styled from 'styled-components';
import { Link as Links } from 'react-scroll'


export const HomeWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: ${props => props.theme.color.dark};
`

export const LogoWrapper = styled.img`
  width: 40vw;
  height: auto;
  @media screen and (max-width: 700px) {
    width: 80vw;
  }
`

export const ButtonWrapper = styled(Links)`
  color: ${props => props.theme.color.dark};
  background-color: ${props => props.theme.color.primary};
  cursor: pointer;
  width: 30vw;
  border-radius: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-weight: bold;

  &:hover {
    background-color: ${props => props.theme.color.secondary};
  }

  @media screen and (max-width: 700px) {
    width: 60vw;
  }
`
