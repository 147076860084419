import React from 'react'
import { ServiceBox, ServiceBoxWrapper, ServiceImg, ServiceText, ServicesWrapper } from './ServicesElements'
import tire from "../../assets/images/tire.png"
import car from "../../assets/images/car.png"
import vus from "../../assets/images/vus.png"

const ServicesPage = () => {
  return (
    <ServicesWrapper>
      <ServiceBox>
        <ServiceText ml>ACHAT & VENTE DE PNEUS</ServiceText>
        <ServiceImg src={tire} />
      </ServiceBox>
      <ServiceBoxWrapper>
        <ServiceBox>
          <ServiceText ml>CHANGEMENT DE PNEUS</ServiceText>
          <br />
          <ServiceText ml>50$</ServiceText>
          <ServiceText>VOITURE, BERLINE, PETIT VUS</ServiceText>
          <ServiceImg src={car} />
          <br />
          <ServiceText ml>60$</ServiceText>
          <ServiceText>VUS, PICKUP, CAMIONNETTE</ServiceText>
          <ServiceImg src={vus} />
        </ServiceBox>
        {/* <ServiceBox>
          <ServiceText>CHANGEMENT DE PNEUS</ServiceText>
          <ServiceText ml>60$</ServiceText>
          <ServiceText>VUS, PICKUP, CAMIONNETTE</ServiceText>
          <ServiceImg src={vus} />
        </ServiceBox> */}
      </ServiceBoxWrapper>
      <ServiceBox>
        <ServiceText ml>DÉPANNAGE</ServiceText>
        <ServiceText>RÉPARATION DE PNEUS CREVÉS</ServiceText>
        <ServiceText>INSTALLATION PNEUS DE SECOURS</ServiceText>
      </ServiceBox>
    </ServicesWrapper>
  )
}

export default ServicesPage