import React from 'react'
import { FooterLogo, FooterRights, FooterWrapper } from './FooterElements'
import logo from "../../assets/images/footer-logo.png"

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterLogo src={logo}/>
      <FooterRights>© Tous droits réservés.</FooterRights>
    </FooterWrapper>
  )
}

export default Footer