import React, { useState, useRef } from 'react'
import { ContactWrapper, Sections, FormWrapper, NamesWrapper, Input, MessageInput, SubmitInput, ContactTitle, OrWrapper, ContactImg, ContactText, OrText, Phone, PinImg, Region, RegionSub, AllRegions, Regions, RegionText } from './ContactElements'
import { Store } from 'react-notifications-component';
import emailjs from '@emailjs/browser';
import line from "../../assets/images/line.png"
import pin from "../../assets/images/pin.png"

const ContactPage = () => {
  const [name, setName] = useState('');
  const [zip, setZIP] = useState('');
  const [phone, setPhone] = useState('');
  const [city, setCity] = useState('');
  const [message, setMessage] = useState('');


  const resetForm = (e) => {
    setName('');
    setCity('');
    setZIP('');
    setPhone('');
    setMessage('');
  }

  const notifySuccess = (e) => {
    e.preventDefault();
    resetForm();
    Store.addNotification({
      title: "Message envoyé avec succès!",
      message: "Je serai en contact avec vous sous peu.",
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });
  }

  var templateParams = {
    name: name,
    zip: zip,
    phone: phone,
    city: city,
    message: message
  };

  const sendEmail = (e) => {
    e.preventDefault(); // prevents the page from reloading when you hit “Send”

    emailjs.init("4yPoYruMXRm_J5S23");
    emailjs.send('service_k9cdrx5', 'template_bfxsfie', templateParams)
      .then((result) => {
        console.log(result)
        notifySuccess(e);
      }, (error) => {
        // show the user an error
      });
    resetForm();
  };

  return (
    <ContactWrapper id="contact">
      <Sections>
        <ContactTitle>CONTACTEZ-NOUS DIRECTEMENT POUR CÉDULER VOTRE CHANGEMENT DE PNEUS!</ContactTitle>
        <FormWrapper onSubmit={(e) => sendEmail(e)}>
          <NamesWrapper>
            <Input type="text" placeholder='Nom' required value={name} onChange={(event) => setName(event.target.value)} />
            <Input type="text" placeholder='Numéro de téléphone' required value={phone} onChange={(event) => setPhone(event.target.value)} />
          </NamesWrapper>
          <NamesWrapper>
            <Input type="text" placeholder='Ville' required value={city} onChange={(event) => setCity(event.target.value)} />
            <Input type="text" placeholder='Code postal' required value={zip} onChange={(event) => setZIP(event.target.value)} />
          </NamesWrapper>
          <MessageInput type="textarea" wrap='hard' placeholder='Donnez-nous 3 plages de disponibilités ainsi que votre type de voiture..' value={message} onChange={(event) => setMessage(event.target.value)} />
          <SubmitInput type="submit" value="Envoyer" />
        </FormWrapper>
        <OrWrapper>
          <ContactImg src={line} />
          <OrText>OU</OrText>
          <ContactImg src={line} />
        </OrWrapper>
        <ContactText>PAR TÉLÉPHONE</ContactText>
        <Phone>514-746-2593</Phone>
      </Sections>
      <Sections>
        <PinImg src={pin} />
        <Region>NOS RÉGIONS</Region>
        <RegionSub>RIVE-SUD DE MONTRÉAL</RegionSub>
        <AllRegions>
          <Regions>
            <RegionText>Beloeil</RegionText>
            <RegionText>Boucherville</RegionText>
            <RegionText>Brossard</RegionText>
            <RegionText>Candiac</RegionText>
            <RegionText>Carignan</RegionText>
            <RegionText>Chambly</RegionText>
            <RegionText>Châteauguay</RegionText>
            <RegionText>Delson</RegionText>
            <RegionText>La Prairie</RegionText>
            <RegionText>Longueuil</RegionText>
            <RegionText>McMasterville</RegionText>
            <RegionText>Mont-Saint-Hilaire</RegionText>
          </Regions>
          <Regions>
            <RegionText>Otterburn Park</RegionText>
            <RegionText>Saint-Basile-le-Grand</RegionText>
            <RegionText>Saint-Bruno-de-Montarville</RegionText>
            <RegionText>Sainte-Catherine</RegionText>
            <RegionText>Saint-Constant</RegionText>
            <RegionText>Saint-Isidore</RegionText>
            <RegionText>Sainte-Julie</RegionText>
            <RegionText>Saint-Lambert</RegionText>
            <RegionText>Saint-Philippe</RegionText>
            <RegionText>Saint-Jean-sur-Richelieu</RegionText>
            <RegionText>Varennes</RegionText>
            <RegionText>Salaberry-de-Valleyfield</RegionText>
          </Regions>
        </AllRegions>
      </Sections>
    </ContactWrapper>
  )
}

export default ContactPage