import React from 'react'
import HomePage from '../components/Home'
import ParallaxPage from '../components/Parallax'
import ServicesPage from '../components/Services'
import ContactPage from '../components/Contact'
import Footer from '../components/Footer'

const Home = () => {
  return (
    <div className='site-wrapper'>
      <HomePage />
      <ServicesPage />
      <ParallaxPage />
      <ContactPage />
      <Footer />
    </div>
  )
}

export default Home